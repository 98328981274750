const AuthRoutes = {
  dashboard: "/dashboard",
  dashboardCourses: "/dashboard-courses",
  userSettings: "/account/user-settings",
  adminPortal: "/admin/portal",
  createAcademy: "/admin/academy/create",
  editAcademy: "/admin/academy/update",
  academies: "/academies",
  academy: "/academy",
  createCourse: "/admin/course/create",
  course: "/course",
  courseSyllabus: "/student/course/syllabus",
  editCourse: "/admin/course/update",
  createLesson: "/admin/lesson/create",
  editLesson: "/admin/lesson/edit",
  resumeLesson: "/lesson/resume",
  expandLesson: "/lesson",
  createAssignment: "/admin/assignment/create",
  editAssignment: "/admin/assignment/edit",
  studentAssignment: "/student/assignment",
  studentAssignmentGrade: "/student/view/assignment/grade",
  studentAssignmentAnswer: "/student/assignment/answer",
  createQuiz: "/admin/quiz/create",
  editQuiz: "/admin/quiz/edit",
  editQuizQuestions: "/admin/quizzes/questions/edit",
  createQuizQuestion: "/admin/create/quiz/question",
  studentQuizQuestions: "/student/quiz/",
  studentQuizGrade: "/student/view/quiz/grade",
  studentQuizAnswers: "/student/view/quiz/answers",
  members: "/members",
  inviteMembers: "/member/invite",
  membersDetails: "/member/details",
  performanceOverview: "/students/performance/overview",
  notifications: "/user/notifications",
  profile: "/user/profile",
};

const NonAuthRoutes = {
  landingPage: "/",
  login: "/login",
  signup: "/sign-up",
  complete: "/complete",
  verifyEmail: "/account/verify",
  forgotPassword: "/forgot/password",
  resetPassword: "/reset/password",
  joinCourse: "/join/course",
  notFound: "/404/notFound",
};

export { AuthRoutes, NonAuthRoutes };
