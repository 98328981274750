/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as CitroneLogo } from "../../components/img/citrone-landing-page-logo.svg";
import { ReactComponent as CitroneLogo2 } from "../../components/img/citrone-logo.svg";
import { ReactComponent as CitroneBgUp } from "../../components/img/citrone-bgup.svg";
import { ReactComponent as CitroneBgDown } from "../../components/img/citrone-bgdown.svg";
import { ReactComponent as CitroneDotsDesktop } from "../../components/img/citrone-signUp-dots-desktop.svg";
import { ReactComponent as CitroneDotsMobile } from "../../components/img/citrone-signUp-dots-mobile.svg";
import { ReactComponent as Slide2Icon1 } from "../../components/img/citrone-landing-page-i1.svg";
import { ReactComponent as Slide2Icon2 } from "../../components/img/citrone-landing-page-i2.svg";
import { ReactComponent as Slide2Icon3 } from "../../components/img/citrone-landing-page-i3.svg";
import { ReactComponent as Underline } from "../../components/img/citrone-landing-page-underline.svg";
import CitroneSlide1Img from "../../components/img/citrone-landing-page-s1.png";
import CitroneSlide3Img from "../../components/img/citrone-landing-page-s3.png";
import CitroneSlide4Img from "../../components/img/citrone-landing-page-s4.png";
import CitroneSlide5Img from "../../components/img/citrone-landing-page-s5.png";
import { AuthRoutes, NonAuthRoutes } from "../../utils/urls";
import authHandler from "../../utils/authHandler";
// import metaTagsAdder from "../../helpers/meta-tags-adder";

function LandingPage() {
  const authenticated = authHandler.get();
  const history = useHistory();

  useEffect(() => {
    const ac = new AbortController();
    if (authenticated !== null) {
      history.push(AuthRoutes.dashboard);
    } else {
      localStorage.clear();
    }
    document.title = "Citrone.co • Learning Experience Platform";
    // metaTagsAdder('property="og:title"', document.title);
    // metaTagsAdder(
    //   'property="og:Image"',
    //   "https://res.cloudinary.com/duzyakdkh/image/upload/v1676818487/citrone-landing-page-s1_xba6dl.png"
    // );
    // metaTagsAdder(
    //   'property="og:Description"',
    //   "Deliver a community-first learning experience! Citrone makes it easy for educators to create learning experiences that positively engage students. Sign Up for a free account!"
    // );
    window.scrollTo(0, 0);
    return function cleanup() {
      ac.abort();
    };
  }, [authenticated, history]);

  /** Routes user to SignUp component */
  const handleSignUpRoute = () => {
    history.push(NonAuthRoutes.signup);
  };

  /** Routes user to Login component */
  const handleLoginRoute = () => {
    history.push(NonAuthRoutes.login);
  };

  /** Displays Slide 1 */
  const slide1 = () => {
    return (
      <>
        <div className=" hidden md:block lg:block pb-14 bg-red-50">
          <div className="flex justify-between pt-10">
            <div>
              <CitroneLogo className="ml-6 lg:ml-24 top-0 left-0 cursor-pointer" />
            </div>
            <div className="flex items-center">
              <p className="mr-12 font-graphikMedium">Have an account already?</p>
              <div>
                <button
                  onClick={handleLoginRoute}
                  type="button"
                  className="citrone-transparent-btn py-2 px-10 mr-6 lg:mr-24 text-md outline-none"
                >
                  Log In
                </button>
              </div>
            </div>
          </div>
          <div className="flex justify-between mt-20 mx-6 lg:mx-24">
            <div className="mr-14">
              <h1 className="relative font-graphikBlack md:leading-snug lg:leading-normal text-5xl mb-10 lg:w-615">
                <span className="pr-2">Deliver a community-first learning</span>
                <span className="relative inline-block">
                  <h1 className="relative z-50">experience</h1>
                  <Underline className="absolute bottom-2 -right-2 flex items-end justify-end z-0" />
                </span>
              </h1>
              <h2 className="text-xl mb-16 lg:w-490">
                Citrone makes it easy for educators to create learning experiences that positively engage students.
              </h2>
              <div className="">
                <button
                  onClick={handleSignUpRoute}
                  type="button"
                  className="sign-up-button-cta citrone-red outline-none"
                >
                  <p className="text-lg">Sign Up for a free account</p>
                </button>
                <div className="sign-up-button-dots">
                  <CitroneDotsDesktop />
                </div>
              </div>
            </div>
            <div>
              <img
                src={CitroneSlide1Img}
                // src="https://res.cloudinary.com/duzyakdkh/image/upload/v1676818487/citrone-landing-page-s1_xba6dl.png"
                alt="Citrone.co Learning Experience"
              />
            </div>
          </div>
        </div>

        <div className="md:hidden lg:hidden h-full pb-10 w-full bg-red-50">
          <div className="flex justify-between pt-4">
            <div>
              <CitroneLogo2 className="ml-4 top-0 left-0 cursor-pointer" />
            </div>
            <div>
              <button
                onClick={handleLoginRoute}
                type="button"
                className="citrone-transparent-btn py-2 px-6 mr-6 text-md outline-none"
              >
                Sign In
              </button>
            </div>
          </div>
          <div className="mt-8 mx-6">
            <div className="">
              <h1 className="font-graphikBlack text-5xl mb-6">
                <span className="pr-2">Deliver a community-first learning</span>
                <div className="relative inline-block">
                  <h1 className="relative z-50">experience</h1>
                  <Underline className="absolute -bottom-1 -right-2 flex items-end justify-end" />
                </div>
              </h1>
            </div>
            <div className="flex justify-center mt-6">
              <img
                src={CitroneSlide1Img}
                // src="https://res.cloudinary.com/duzyakdkh/image/upload/v1676818487/citrone-landing-page-s1_xba6dl.png"
                alt="Citrone.co Learning Experience"
              />
            </div>
            <h2 className="text-xl mt-5 mb-8">
              Citrone makes it easy for educators to create learning experiences that positively engage students.
            </h2>
            <div>
              <button
                onClick={handleSignUpRoute}
                type="button"
                className="sign-up-button-cta-mobile citrone-red outline-none"
              >
                <p className="text-lg">Sign Up for a free account</p>
              </button>
              <div className="sign-up-button-dots-mobile">
                <CitroneDotsMobile />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  /** Displays Slide 2 */
  const slide2 = () => {
    return (
      <>
        <div className="hidden md:block lg:block py-20">
          <div className="flex justify-between mx-6 lg:mx-24">
            <div className="bg-white rounded box-shadow-css px-4 py-2 w-full">
              <Slide2Icon1 className="my-2 mx-4 h-10 w-10" />
              <p className=" font-graphikMedium text-lg py-5 px-4">Create and manage courses seamlessly.</p>
            </div>
            <div className="bg-white mx-8 rounded box-shadow-css px-4 py-2 w-full">
              <Slide2Icon2 className="my-2 mx-4 h-10 w-10" />
              <p className="font-graphikMedium text-lg py-5 px-4">Seamless engagement between tutors and students.</p>
            </div>
            <div className="bg-white rounded box-shadow-css px-4 py-2 w-full">
              <Slide2Icon3 className="my-2 mx-4 h-10 w-10" />
              <p className="font-graphikMedium text-lg py-5 px-4">Give feedback as quickly as possible.</p>
            </div>
          </div>
        </div>

        <div className="md:hidden lg:hidden h-full py-10">
          <div className="mx-6">
            <div className="bg-white rounded box-shadow-css px-4 py-2 w-full">
              <Slide2Icon1 className="my-2 mx-4 h-10 w-10" />
              <p className="font-graphikMedium text-lg py-5 px-4">Create and manage courses seamlessly.</p>
            </div>
            <div className="bg-white my-14 rounded box-shadow-css px-4 py-2 w-full">
              <Slide2Icon2 className="my-2 mx-4 h-10 w-10" />
              <p className="font-graphikMedium text-lg py-5 px-4">Seamless engagement between tutors and students.</p>
            </div>
            <div className="bg-white rounded box-shadow-css px-4 py-2 w-full">
              <Slide2Icon3 className="my-2 mx-4 h-10 w-10" />
              <p className="font-graphikMedium text-lg py-5 px-4">Give feedback as quickly as possible.</p>
            </div>
          </div>
        </div>
      </>
    );
  };

  /** Displays Slide 3 */
  const slide3 = () => {
    return (
      <>
        <div className="hidden md:block lg:block">
          <div className="mx-6 lg:mx-24">
            <div className="flex justify-between w-full items-center">
              <div className="w-6/12">
                <img src={CitroneSlide3Img} alt="Citrone.co! Nurture a community-first learning experience" />
              </div>
              <div className="w-6/12 my-4 ml-10">
                <p className="text-xl font-graphikMedium text-gray-400">COMMUNITY-FIRST</p>
                <h2 className=" font-graphikSemibold mt-10 mb-6 text-4xl text-red-500 leading-relaxed">
                  Nurture a community-first learning experience.
                </h2>
                <p className="text-lg leading-loose">
                  Students who feel connected to their learning community are more likely to persist. We foster
                  meaningful connection by ensuring students are never more than 1 click away from their peers and
                  educators.
                </p>
              </div>
            </div>
          </div>
          <div className="ml-6 lg:ml-24 my-12 border-t-2 border-gray-200" />
        </div>

        <div className="md:hidden lg:hidden">
          <div className="mx-6 my-10 ">
            <div className="w-full">
              <div className="flex justify-center">
                <img src={CitroneSlide3Img} alt="Citrone.co! Nurture a community-first learning experience" />
              </div>
              <div className="mt-14">
                <p className="text-lg font-graphikMedium text-gray-400">COMMUNITY-FIRST</p>
                <h2 className="font-graphikSemibold mt-6 mb-4 text-2xl text-red-500 leading-relaxed">
                  Nurture a community-first learning experience.
                </h2>
                <p className="text-base leading-loose">
                  Students who feel connected to their learning community are more likely to persist. We foster
                  meaningful connection by ensuring students are never more than 1 click away from their peers and
                  educators.
                </p>
              </div>
            </div>
          </div>
          <div className="ml-6 my-12 border-t-2 border-gray-200" />
        </div>
      </>
    );
  };

  /** Displays Slide 4 */
  const slide4 = () => {
    return (
      <>
        <div className="hidden md:block lg:block">
          <div className="mx-6 lg:mx-24">
            <div className="flex justify-between w-full items-center">
              <div className="w-6/12 my-4">
                <p className="text-xl font-graphikMedium text-gray-400">EFFECTIVE LEARNING</p>
                <h2 className="font-graphikSemibold mt-10 mb-6 text-4xl text-red-500 leading-relaxed">
                  Engaging and effective training.
                </h2>
                <p className="text-lg leading-loose">
                  Engage learners with video interactions, note-taking, slides, e-books, self-assessment, certificates,
                  and much more.
                </p>
              </div>
              <div className="w-6/12 ml-20">
                <img src={CitroneSlide4Img} alt="Citrone.co! Engaging and effective training" />
              </div>
            </div>
          </div>
          <div className="ml-6 lg:ml-24 my-12 border-t-2 border-gray-200" />
        </div>

        <div className="md:hidden lg:hidden">
          <div className="mx-6 my-10 ">
            <div className="flex justify-center">
              <img src={CitroneSlide4Img} alt="Citrone.co! Engaging and effective training" />
            </div>
            <div className="w-full">
              <div className="mt-14">
                <p className="text-lg font-graphikMedium text-gray-400">EFFECTIVE LEARNING</p>
                <h2 className="font-graphikSemibold mt-6 mb-4 text-2xl text-red-500 leading-relaxed">
                  Engaging and effective training.
                </h2>
                <p className="text-base leading-loose">
                  Engage learners with video interactions, note-taking, slides, e-books, self-assessment, certificates,
                  and much more.
                </p>
              </div>
            </div>
          </div>
          <div className="ml-6 my-12 border-t-2 border-gray-200" />
        </div>
      </>
    );
  };

  /** Displays Slide 5 */
  const slide5 = () => {
    return (
      <>
        <div className="hidden md:block lg:block">
          <div className="mx-6 lg:mx-24">
            <div className="flex justify-between w-full items-center">
              <div className="w-6/12">
                <img src={CitroneSlide5Img} alt="Citrone.co! Engagement Insights" />
              </div>
              <div className="w-6/12 my-4 ml-10">
                <p className="text-xl font-graphikMedium text-gray-400">REPORTING</p>
                <h2 className="font-graphikSemibold mt-10 mb-6 text-4xl text-red-500 leading-relaxed">
                  Engagement Insights.
                </h2>
                <p className="text-lg leading-loose">
                  Quickly see which students are engaged and which are not, so you can take timely action to support
                  students directly from Citrone.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="md:hidden lg:hidden">
          <div className="mx-6 my-10 ">
            <div className="w-full">
              <div className="flex justify-center">
                <img src={CitroneSlide5Img} alt="Citrone.co! Engagement Insights" />
              </div>
              <div className="mt-14">
                <p className="text-lg font-graphikMedium text-gray-400">REPORTING</p>
                <h2 className="font-graphikSemibold mt-6 mb-4 text-2xl text-red-500 leading-relaxed">
                  Engagement Insights.
                </h2>
                <p className="text-base leading-loose">
                  Quickly see which students are engaged and which are not, so you can take timely action to support
                  students directly from Citrone.
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  /** Displays Slide 5 */
  const slide6 = () => {
    return (
      <>
        <div className="hidden md:block lg:block mt-32 mb-16 ">
          <div className="flex bg-red-50 h-72 items-center mx-40 lg:mx-72">
            <div className="m-auto">
              <div className="flex justify-center mb-8">
                <h2 className="font-graphikSemibold text-4xl leading-normal items-center w-4/5 text-center">
                  Want to start learning easily today?
                </h2>
              </div>
              <div className="flex mt-4">
                <button
                  onClick={handleSignUpRoute}
                  type="button"
                  className="px-4 lg:px-12 py-3 text-white text-xl m-auto citrone-red z-50 outline-none"
                >
                  Sign Up for a free account
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="md:hidden lg:hidden mt-16 mb-16 mx-10 bg-red-50 py-4 text-center">
          <h2 className="text-center font-graphikSemibold mb-6 mt-4 text-2xl px-6">
            Want to start learning easily today?
          </h2>
          <button
            onClick={handleSignUpRoute}
            type="button"
            className="px-6 py-4 text-white mb-4 citrone-red z-50 outline-none"
          >
            Sign Up for a free account
          </button>
        </div>
      </>
    );
  };

  return (
    <div>
      <CitroneBgUp className="citrone-bg absolute top-0 right-0" />
      <CitroneBgDown className="citrone-bg absolute bottom-0 left-0" />
      {slide1()}
      {slide2()}
      {slide3()}
      {slide4()}
      {slide5()}
      {slide6()}
    </div>
  );
}

export default LandingPage;
