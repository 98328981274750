import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/authSlice";
import userReducer from "./user/userSlice";
import academyReducer from "./academy/academySlice";
import courseReducer from "./course/courseSlice";
import lessonReducer from "./lesson/lessonSlice";
import assignmentReducer from "./assignment/assignmentSlice";
import quizReducer from "./quiz/quizSlice";
import membersReducer from "./members/membersSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    academy: academyReducer,
    course: courseReducer,
    lesson: lessonReducer,
    assignment: assignmentReducer,
    quiz: quizReducer,
    members: membersReducer,
  },
});

export default store;
