/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: undefined,
  isSignedIn: false,
  userTitle: undefined,
  emailFromLogin: undefined,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginUser(state, action) {
      const { token, isSignedIn, emailFromLogin } = action.payload;

      state.token = token;
      state.isSignedIn = isSignedIn;
      state.emailFromLogin = emailFromLogin;
    },
    signupUser(state, action) {
      const { token, userTitle } = action.payload;

      state.token = token;
      state.userTitle = userTitle;
    },
  },
});

export const userTitleInitial = (state) => state.auth.userTitle;
export const userIsSignedIn = (state) => state.auth.isSignedIn;
export const accessToken = (state) => state.auth.token;
export const emailFromLogin = (state) => state.auth.emailFromLogin;
export const { loginUser, signupUser } = authSlice.actions;

export default authSlice.reducer;
