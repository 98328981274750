/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  _id: undefined,
  firstName: undefined,
  lastName: undefined,
  verified: undefined,
  email: undefined,
  fullName: undefined,
  userInitials: undefined,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setCurrentUser(state, action) {
      const { _id, firstName, lastName, verified, email, fullName, userInitials } = action.payload;

      // eslint-disable-next-line no-underscore-dangle
      state._id = _id;
      state.firstName = firstName;
      state.lastName = lastName;
      state.verified = verified;
      state.email = email;
      state.fullName = fullName;
      state.userInitials = userInitials;
    },
  },
});

// eslint-disable-next-line no-underscore-dangle
export const userId = (state) => state.user._id;
export const userFirstName = (state) => state.user.firstName;
export const userLastName = (state) => state.user.lastName;
export const userVerified = (state) => state.user.verified;
export const userEmail = (state) => state.user.email;
export const userFullName = (state) => state.user.fullName;
export const userInitials = (state) => state.user.userInitials;

export const { setCurrentUser } = userSlice.actions;

export default userSlice.reducer;
