/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  content: undefined,
  currentListOfLessons: [],
  currentLessonId: undefined,
  currentLessonTitle: undefined,
  currentLessonBlocks: [],
  lessonBlocks: [],
  lessonCourse: undefined,
  lessonCreatedAt: undefined,
  lessonCreatedBy: undefined,
  lessonLastModified: undefined,
  lessonTitle: undefined,
  lessonId: undefined,
  lessonlikes: [],
  percentageProgress: undefined,
};

const lessonSlice = createSlice({
  name: "lesson",
  initialState,
  reducers: {
    setEditorJSContent(state, action) {
      const { content } = action.payload;
      state.content = content;
    },
    setLessonDetails(state, action) {
      const {
        currentListOfLessons,
        currentLessonId,
        currentLessonTitle,
        currentLessonBlocks,
        lessonBlocks,
        lessonCourse,
        lessonCreatedAt,
        lessonCreatedBy,
        lessonLastModified,
        lessonTitle,
        lessonId,
        lessonLikes,
        percentageProgress,
      } = action.payload;

      state.currentListOfLessons = currentListOfLessons;
      state.currentLessonId = currentLessonId;
      state.currentLessonTitle = currentLessonTitle;
      state.currentLessonBlocks = currentLessonBlocks;
      state.lessonBlocks = lessonBlocks;
      state.lessonCourse = lessonCourse;
      state.lessonCreatedAt = lessonCreatedAt;
      state.lessonCreatedBy = lessonCreatedBy;
      state.lessonLastModified = lessonLastModified;
      state.lessonTitle = lessonTitle;
      state.lessonId = lessonId;
      state.lessonLikes = lessonLikes;
      state.percentageProgress = percentageProgress;
    },
  },
});

export const editorJSContent = (state) => state.lesson.content;

export const currentListOfLessons = (state) => state.lesson.currentListOfLessons;
export const currentLessonId = (state) => state.lesson.currentLessonId;
export const currentLessonTitle = (state) => state.lesson.currentLessonTitle;
export const currentLessonBlocks = (state) => state.lesson.currentLessonBlocks;

export const lessonBlocks = (state) => state.lesson.lessonBlocks;
export const lessonCourse = (state) => state.lesson.lessonCourse;
export const lessonCreatedAt = (state) => state.lesson.lessonCreatedAt;
export const lessonCreatedBy = (state) => state.lesson.lessonCreatedBy;
export const lessonLastModified = (state) => state.lesson.lessonLastModified;
export const lessonTitle = (state) => state.lesson.lessonTitle;
export const lessonId = (state) => state.lesson.lessonId;
export const lessonLikes = (state) => state.lesson.lessonLikes;
export const percentageProgress = (state) => state.lesson.percentageProgress;

export const { setEditorJSContent, setLessonDetails } = lessonSlice.actions;

export default lessonSlice.reducer;
