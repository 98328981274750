/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isCurrentAcademyId: undefined,
  isCurrentAcademyName: undefined,
  isCurrentAcademyImage: undefined,
  isCurrentAcademyDescription: undefined,
};

const academySlice = createSlice({
  name: "academy",
  initialState,
  reducers: {
    setCurrentAcademyDetails(state, action) {
      const { isCurrentAcademyId, isCurrentAcademyName, isCurrentAcademyImage, isCurrentAcademyDescription } =
        action.payload;

      state.isCurrentAcademyId = isCurrentAcademyId;
      state.isCurrentAcademyName = isCurrentAcademyName;
      state.isCurrentAcademyImage = isCurrentAcademyImage;
      state.isCurrentAcademyDescription = isCurrentAcademyDescription;
    },
  },
});

export const isCurrentAcademyId = (state) => state.academy.isCurrentAcademyId;
export const isCurrentAcademyName = (state) => state.academy.isCurrentAcademyName;
export const isCurrentAcademyImage = (state) => state.academy.isCurrentAcademyImage;
export const isCurrentAcademyDescription = (state) => state.academy.isCurrentAcademyDescription;

export const { setCurrentAcademyDetails } = academySlice.actions;

export default academySlice.reducer;
