/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable prefer-const */
/* eslint-disable no-console */
/* eslint-disable no-alert */
/* eslint-disable object-shorthand */
/* eslint-disable import/no-cycle */
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
// import { BrowserRouter as Router } from "react-router-dom";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Provider } from "react-redux";
import configureStore from "./redux/index";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import App from "./App";

const store = configureStore;

export const history = createBrowserHistory();

/** Here we setup React Redux and user Authentication to Signed In Users */
const Root = () => {
  return (
    <Provider store={store}>
      <Router history={history}>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </Router>
    </Provider>
  );
};

const Render = () => {
  ReactDOM.render(<Root />, document.getElementById("root"));
};
Render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener("statechange", (event) => {
        if (event.target.state === "activated") {
          if (window.confirm("A new version of Citrone ❤️ is available!, Update now?")) {
            window.location.reload();
          }
        }
      });
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
    }
  },
});

// const divInstall = document.getElementById("installContainer");
// const butInstall = document.getElementById("butInstall");
// const closeBtn = document.getElementById("closeBtn");

// const iosDivInstall = document.getElementById("iosInstallContainer");
// const iosCloseBtn = document.getElementById("iosCloseBtn");

// window.addEventListener("beforeinstallprompt", (event) => {
//   console.log("👍", "beforeinstallprompt", event);
//   // Stash the event so it can be triggered later.
//   window.deferredPrompt = event;
//   // Remove the 'hidden' class from the install button container
//   divInstall.classList.toggle("installHidden", false);
// });

// closeBtn.addEventListener("click", async () => {
//   console.log("👍", "closeBtn-cliked");
//   // Hide the install button.
//   divInstall.classList.toggle("installHidden", true);
// });

// butInstall.addEventListener("click", async () => {
//   console.log("👍", "butInstall-clicked");
//   const promptEvent = window.deferredPrompt;
//   if (!promptEvent) {
//     // The deferred prompt isn't available.
//     return;
//   }
//   // Show the install prompt.
//   promptEvent.prompt();
//   // Log the result
//   const result = await promptEvent.userChoice;
//   console.log("👍", "userChoice", result);
//   // Reset the deferred prompt variable, since prompt() can only be called once.
//   window.deferredPrompt = null;
//   // Hide the install button.
//   divInstall.classList.toggle("installHidden", true);
// });

// // Use this appinstalled event handler to add the event window object.
// // Then you can Add or Log the install events to analytics or other mechanisms.
// window.addEventListener("appinstalled", (event) => {
//   console.log("👍", "appinstalled", event);
//   // Clear the deferredPrompt so it can be garbage collected
//   window.deferredPrompt = null;
// });

// function showIosInstall() {
//   console.log("👍", "iosbeforeinstallprompt");
//   iosDivInstall.classList.toggle("iosHidden", false);
// }

// iosCloseBtn.addEventListener("click", async () => {
//   // Hide the ios install button.
//   iosDivInstall.classList.toggle("iosHidden", true);
// });

// // Detects if device is on iOS
// const isIos = () => {
//   const userAgent = window.navigator.userAgent.toLowerCase();
//   return /iphone|ipad|ipod/.test(userAgent);
// };

// // Detects if device is in standalone mode
// const isInStandaloneMode = () =>
//   "standalone" in window.navigator && window.navigator.standalone;

// // Checks if should display pwa install notification:
// if (isIos() && !isInStandaloneMode()) {
//   // this.setState({ showInstallMessage: true });
//   showIosInstall();
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
