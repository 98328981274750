/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  content: undefined,

  isCurrentAssignmentId: undefined,
  isCurrentAssignmentTitle: undefined,
  isCurrentAssignmentBlocks: [],

  assignmentBlocks: [],
  isCurrentAssignmentDueDate: undefined,
  isCurrentAssignmentCreatedAt: undefined,

  currentStudentAssignmentLink: undefined,
  currentStudentAssignmentAnswer: undefined,
  currentStudentAssignmentResources: undefined,
  currentStudentAssignmentAnswerId: undefined,
  currentStudentAssignmentTutorsRemark: undefined,
  currentStudentAssignmentTutorsScore: undefined,
};

const assignmentSlice = createSlice({
  name: "assignment",
  initialState,
  reducers: {
    setEditorJSContent(state, action) {
      const { content } = action.payload;

      state.content = content;
    },
    setCurrentAssignmentDetails(state, action) {
      const {
        isCurrentAssignmentId,
        isCurrentAssignmentTitle,
        isCurrentAssignmentBlocks,
        assignmentBlocks,
        isCurrentAssignmentDueDate,
        isCurrentAssignmentCreatedAt,

        currentStudentAssignmentLink,
        currentStudentAssignmentAnswer,
        currentStudentAssignmentResources,
        currentStudentAssignmentAnswerId,
        currentStudentAssignmentTutorsRemark,
        currentStudentAssignmentTutorsScore,
      } = action.payload;

      state.isCurrentAssignmentId = isCurrentAssignmentId;
      state.isCurrentAssignmentTitle = isCurrentAssignmentTitle;
      state.isCurrentAssignmentBlocks = isCurrentAssignmentBlocks;
      state.assignmentBlocks = assignmentBlocks;
      state.isCurrentAssignmentDueDate = isCurrentAssignmentDueDate;
      state.isCurrentAssignmentCreatedAt = isCurrentAssignmentCreatedAt;

      state.currentStudentAssignmentLink = currentStudentAssignmentLink;
      state.currentStudentAssignmentAnswer = currentStudentAssignmentAnswer;
      state.currentStudentAssignmentResources = currentStudentAssignmentResources;
      state.currentStudentAssignmentAnswerId = currentStudentAssignmentAnswerId;
      state.currentStudentAssignmentTutorsRemark = currentStudentAssignmentTutorsRemark;
      state.currentStudentAssignmentTutorsScore = currentStudentAssignmentTutorsScore;
    },
  },
});

export const editorJSContent = (state) => state.assignment.content;

export const isCurrentAssignmentId = (state) => state.assignment.isCurrentAssignmentId;
export const isCurrentAssignmentTitle = (state) => state.assignment.isCurrentAssignmentTitle;
export const isCurrentAssignmentBlocks = (state) => state.assignment.isCurrentAssignmentBlocks;

export const assignmentBlocks = (state) => state.assignment.assignmentBlocks;
export const isCurrentAssignmentDueDate = (state) => state.assignment.isCurrentAssignmentDueDate;
export const isCurrentAssignmentCreatedAt = (state) => state.assignment.isCurrentAssignmentCreatedAt;

export const currentStudentAssignmentLink = (state) => state.assignment.currentStudentAssignmentLink;
export const currentStudentAssignmentAnswer = (state) => state.assignment.currentStudentAssignmentAnswer;
export const currentStudentAssignmentResources = (state) => state.assignment.currentStudentAssignmentResources;
export const currentStudentAssignmentAnswerId = (state) => state.assignment.currentStudentAssignmentAnswerId;
export const currentStudentAssignmentTutorsRemark = (state) => state.assignment.currentStudentAssignmentTutorsRemark;
export const currentStudentAssignmentTutorsScore = (state) => state.assignment.currentStudentAssignmentTutorsScore;

export const { setEditorJSContent, setCurrentAssignmentDetails } = assignmentSlice.actions;

export default assignmentSlice.reducer;
