/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isCurrentMembersInCourse: [],
  isCurrentMembersFirstName: undefined,
  isCurrentMembersLastName: undefined,
  isCurrentMembersEmail: undefined,
  isCurrentMembersDateJoined: undefined,
  isCurrentMembersLastLogin: undefined,
  isCurrentMembersId: undefined,
  isCurrentMemberRole: undefined,
};

const membersSlice = createSlice({
  name: "members",
  initialState,
  reducers: {
    setCurrentMembersDetails(state, action) {
      const {
        isCurrentMembersInCourse,
        isCurrentMembersFirstName,
        isCurrentMembersLastName,
        isCurrentMembersEmail,
        isCurrentMembersDateJoined,
        isCurrentMembersLastLogin,
        isCurrentMembersId,
        isCurrentMemberRole,
      } = action.payload;

      state.isCurrentMembersInCourse = isCurrentMembersInCourse;
      state.isCurrentMembersFirstName = isCurrentMembersFirstName;
      state.isCurrentMembersLastName = isCurrentMembersLastName;
      state.isCurrentMembersEmail = isCurrentMembersEmail;
      state.isCurrentMembersDateJoined = isCurrentMembersDateJoined;
      state.isCurrentMembersLastLogin = isCurrentMembersLastLogin;
      state.isCurrentMembersId = isCurrentMembersId;
      state.isCurrentMemberRole = isCurrentMemberRole;
    },
  },
});

export const isCurrentMembersInCourse = (state) => state.members.isCurrentMembersInCourse;
export const isCurrentMembersFirstName = (state) => state.members.isCurrentMembersFirstName;
export const isCurrentMembersLastName = (state) => state.members.isCurrentMembersLastName;
export const isCurrentMembersEmail = (state) => state.members.isCurrentMembersEmail;
export const isCurrentMembersDateJoined = (state) => state.members.isCurrentMembersDateJoined;
export const isCurrentMembersLastLogin = (state) => state.members.isCurrentMembersLastLogin;
export const isCurrentMembersId = (state) => state.members.isCurrentMembersId;
export const isCurrentMemberRole = (state) => state.members.isCurrentMemberRole;

export const { setCurrentMembersDetails } = membersSlice.actions;

export default membersSlice.reducer;
