/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Route, useHistory } from "react-router-dom";
import authHandler from "./authHandler";
import { NonAuthRoutes } from "./urls";

function PrivateRoute({ comp: Component, ...rest }) {
  const history = useHistory();
  const isAuthenticated = authHandler.get();

  /** Logs User Out By Clearing Token and Routing to Landing Page */
  const handleLogout = () => {
    localStorage.clear();
    history.push(NonAuthRoutes.landingPage);
  };

  if (!isAuthenticated) {
    handleLogout();
    return <Route {...rest} component={Component} />;
  }
  return <Route {...rest} component={Component} />;
}

export default PrivateRoute;
