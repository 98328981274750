import { setCurrentUser } from "../redux/user/userSlice";
import store from "../redux";

/* eslint-disable object-shorthand */
const authHandler = {
  handle(TOKEN) {
    this.set(TOKEN);
  },
  set(TOKEN) {
    localStorage.setItem("verified", TOKEN);
  },
  get() {
    return localStorage.getItem("verified");
  },
  delete() {
    return localStorage.removeItem("verified");
  },
  setUserInfo(user) {
    // const user = {
    //   _id: _id,
    //   name: name,
    //   firstName: firstName,
    //   lastName: lastName,
    //   email: email,
    //   emailVerified: emailVerified,
    //   userInitials: userInitials,
    // };
    localStorage.setItem("user", JSON.stringify(user));
    store.dispatch(
      setCurrentUser({
        ...user,
      })
    );
  },
  getUser() {
    return JSON.parse(localStorage.getItem("user"));
  },
  deleteUser() {
    return JSON.parse(localStorage.removeItem("user"));
  },
};

export default authHandler;
