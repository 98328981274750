/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isCurrentQuizId: undefined,
  isCurrentQuizTitle: undefined,
  isCurrentQuizDueDate: undefined,
  isCurrentQuizCreatedAt: undefined,
  isCurrentQuizInstruction: undefined,
  isCurrentQuizResources: undefined,
  isCurrentQuizStudentId: undefined,
  isCurrentQuizQuestionType: undefined,
  isCurrentQuizQuestions: [],
  // isCurrentQuizQuestionOption1: undefined,
  // isCurrentQuizQuestionOption2: undefined,
  // isCurrentQuizQuestionOption3: undefined,
  // isCurrentQuizQuestionOption4: undefined,
  // isCurrentQuizQuestionOption5: undefined,
};

const quizSlice = createSlice({
  name: "quiz",
  initialState,
  reducers: {
    setCurrentQuizDetails(state, action) {
      const {
        isCurrentQuizId,
        isCurrentQuizTitle,
        isCurrentQuizDueDate,
        isCurrentQuizCreatedAt,
        isCurrentQuizInstruction,
        isCurrentQuizResources,
        isCurrentQuizStudentId,
        isCurrentQuizQuestionType,
        isCurrentQuizQuestions,
        // isCurrentQuizQuestionOption1,
        // isCurrentQuizQuestionOption2,
        // isCurrentQuizQuestionOption3,
        // isCurrentQuizQuestionOption4,
        // isCurrentQuizQuestionOption5,
      } = action.payload;

      state.isCurrentQuizId = isCurrentQuizId;
      state.isCurrentQuizTitle = isCurrentQuizTitle;
      state.isCurrentQuizDueDate = isCurrentQuizDueDate;
      state.isCurrentQuizCreatedAt = isCurrentQuizCreatedAt;
      state.isCurrentQuizInstruction = isCurrentQuizInstruction;
      state.isCurrentQuizResources = isCurrentQuizResources;
      state.isCurrentQuizStudentId = isCurrentQuizStudentId;
      state.isCurrentQuizQuestionType = isCurrentQuizQuestionType;
      state.isCurrentQuizQuestions = isCurrentQuizQuestions;
      // state.isCurrentQuizQuestionOption1 = isCurrentQuizQuestionOption1;
      // state.isCurrentQuizQuestionOption2 = isCurrentQuizQuestionOption2;
      // state.isCurrentQuizQuestionOption3 = isCurrentQuizQuestionOption3;
      // state.isCurrentQuizQuestionOption4 = isCurrentQuizQuestionOption4;
      // state.isCurrentQuizQuestionOption5 = isCurrentQuizQuestionOption5;
    },
  },
});

export const isCurrentQuizId = (state) => state.quiz.isCurrentQuizId;
export const isCurrentQuizTitle = (state) => state.quiz.isCurrentQuizTitle;
export const isCurrentQuizDueDate = (state) => state.quiz.isCurrentQuizDueDate;
export const isCurrentQuizCreatedAt = (state) => state.quiz.isCurrentQuizCreatedAt;
export const isCurrentQuizInstruction = (state) => state.quiz.isCurrentQuizInstruction;
export const isCurrentQuizResources = (state) => state.quiz.isCurrentQuizResources;
export const isCurrentQuizStudentId = (state) => state.quiz.isCurrentQuizStudentId;
export const isCurrentQuizQuestionType = (state) => state.quiz.isCurrentQuizQuestionType;
export const isCurrentQuizQuestions = (state) => state.quiz.isCurrentQuizQuestions;
// export const isCurrentQuizQuestionOption1 = (state) =>
//   state.quiz.isCurrentQuizQuestionOption1;
// export const isCurrentQuizQuestionOption2 = (state) =>
//   state.quiz.isCurrentQuizQuestionOption2;
// export const isCurrentQuizQuestionOption3 = (state) =>
//   state.quiz.isCurrentQuizQuestionOption3;
// export const isCurrentQuizQuestionOption4 = (state) =>
//   state.quiz.isCurrentQuizQuestionOption4;
// export const isCurrentQuizQuestionOption5 = (state) =>
//   state.quiz.isCurrentQuizQuestionOption5;

export const { setCurrentQuizDetails } = quizSlice.actions;

export default quizSlice.reducer;
