/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isCurrentCourseId: undefined,
  isCurrentCourseTitle: undefined,
  isCurrentCourseDescription: undefined,
  isCurrentCourseStartDate: undefined,
  isCurrentCourseEndDate: undefined,
  isCurrentCourseImage: undefined,
  isCurrentCourseUserRole: undefined,
  isCurrentMemberCourses: undefined,
  isCurrentLessonImageForResume: undefined,
  isCurrentCourseAdmin: undefined,
  isCurrentCourseGroupsFigure: undefined,
  isCurrentCourseAssignmentFigure: undefined,
  isCurrentCourseQuizFigure: undefined,
};

const courseSlice = createSlice({
  name: "course",
  initialState,
  reducers: {
    setCurrentCourseDetails(state, action) {
      const {
        isCurrentCourseId,
        isCurrentCourseTitle,
        isCurrentCourseDescription,
        isCurrentCourseStartDate,
        isCurrentCourseEndDate,
        isCurrentCourseImage,
        isCurrentCourseUserRole,
        isCurrentMemberCourses,
        isCurrentLessonImageForResume,
        isCurrentCourseAdmin,
        isCurrentCourseGroupsFigure,
        isCurrentCourseAssignmentFigure,
        isCurrentCourseQuizFigure,
      } = action.payload;

      state.isCurrentCourseId = isCurrentCourseId;
      state.isCurrentCourseTitle = isCurrentCourseTitle;
      state.isCurrentCourseDescription = isCurrentCourseDescription;
      state.isCurrentCourseStartDate = isCurrentCourseStartDate;
      state.isCurrentCourseEndDate = isCurrentCourseEndDate;
      state.isCurrentCourseImage = isCurrentCourseImage;
      state.isCurrentCourseUserRole = isCurrentCourseUserRole;
      state.isCurrentMemberCourses = isCurrentMemberCourses;
      state.isCurrentLessonImageForResume = isCurrentLessonImageForResume;
      state.isCurrentCourseAdmin = isCurrentCourseAdmin;
      state.isCurrentCourseGroupsFigure = isCurrentCourseGroupsFigure;
      state.isCurrentCourseAssignmentFigure = isCurrentCourseAssignmentFigure;
      state.isCurrentCourseQuizFigure = isCurrentCourseQuizFigure;
    },
  },
});

export const isCurrentCourseId = (state) => state.course.isCurrentCourseId;
export const isCurrentCourseTitle = (state) => state.course.isCurrentCourseTitle;
export const isCurrentCourseDescription = (state) => state.course.isCurrentCourseDescription;
export const isCurrentCourseStartDate = (state) => state.course.isCurrentCourseStartDate;
export const isCurrentCourseEndDate = (state) => state.course.isCurrentCourseEndDate;
export const isCurrentCourseImage = (state) => state.course.isCurrentCourseImage;
export const isCurrentCourseUserRole = (state) => state.course.isCurrentCourseUserRole;
export const isCurrentMemberCourses = (state) => state.course.isCurrentMemberCourses;
export const isCurrentLessonImageForResume = (state) => state.course.isCurrentLessonImageForResume;
export const isCurrentCourseAdmin = (state) => state.course.isCurrentCourseAdmin;
export const isCurrentCourseGroupsFigure = (state) => state.course.isCurrentCourseGroupsFigure;
export const isCurrentCourseAssignmentFigure = (state) => state.course.isCurrentCourseAssignmentFigure;
export const isCurrentCourseQuizFigure = (state) => state.course.isCurrentCourseQuizFigure;

export const { setCurrentCourseDetails } = courseSlice.actions;

export default courseSlice.reducer;
