/* eslint-disable import/no-cycle */
import React, { Suspense } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { AuthRoutes, NonAuthRoutes } from "./urls";
import PrivateRoute from "./privateRoute";
import CitroneLoadingIcon from "../assets/svg/citrone-loading-icon";
import LandingPage from "../pages/landing-page/landing-page";

const signUp = React.lazy(() => import("../pages/sign-up/sign-up"));
const login = React.lazy(() => import("../pages/login/login"));
const pageNotFound = React.lazy(() => import("../pages/page-not-found/page-not-found"));
const forgotPassword = React.lazy(() => import("../components/forgotPassword/forgotPassword"));

const dashboardCourses = React.lazy(() => import("../pages/dashboard-courses/dashboard-courses"));
const userSettings = React.lazy(() => import("../pages/user-settings/user-settings"));

const lazyResetPassword = React.lazy(() => import("../components/forgotPassword/resetPassword/resetPassword"));
const lazyJoinCourse = React.lazy(() => import("../components/admin/members/joinCourse"));
const lazyDashboard = React.lazy(() => import("../pages/dashboard/dashboard"));
const lazyNotifications = React.lazy(() => import("../components/dashboard/notifications"));
const lazyProfile = React.lazy(() => import("../components/dashboard/profile"));
const lazyVerifyEmail = React.lazy(() => import("../components/verifyEmail/verifyEmail"));
const lazyAdminPortal = React.lazy(() => import("../components/admin/adminPortal"));
const lazyAdminCreateAcademy = React.lazy(() => import("../components/admin/academy/createAcademy"));
const lazyAdminEditAcademy = React.lazy(() => import("../components/admin/academy/editAcademy"));
const lazyAdminAcademies = React.lazy(() => import("../components/admin/academy/academies"));
const lazyAdminAcademy = React.lazy(() => import("../components/admin/academy/academy"));
const lazyAdminCreateCourse = React.lazy(() => import("../components/admin/course/createCourse"));
const lazyAdminCourse = React.lazy(() => import("../components/admin/course/course"));
const lazyAdminCourseSyllabus = React.lazy(() => import("../components/admin/course/courseSyllabus"));
const lazyAdminEditCourse = React.lazy(() => import("../components/admin/course/editCourse"));
const lazyAdminCreateLesson = React.lazy(() => import("../components/admin/lesson/createLesson"));
const lazyAdminEditLesson = React.lazy(() => import("../components/admin/lesson/editLesson"));
const lazyExpandLesson = React.lazy(() => import("../components/admin/lesson/expandLesson"));
const lazyResumeLesson = React.lazy(() => import("../components/admin/lesson/resumeLesson"));
const lazyAdminCreateAssignment = React.lazy(() => import("../components/admin/assignment/createAssignment"));
const lazyAdminEditAssignment = React.lazy(() => import("../components/admin/assignment/editAssignment"));
const lazyStudentAssignment = React.lazy(() => import("../components/admin/assignment/studentAnswerAssignment"));
const lazyStudentAssignmentAnswer = React.lazy(() => import("../components/admin/assignment/studentAssignmentAnswer"));
const lazyStudentAssignmentGrade = React.lazy(() => import("../components/admin/assignment/studentAssignmentGrade"));
const lazyAdminCreateQuiz = React.lazy(() => import("../components/admin/quiz/createQuiz"));
const lazyAdminEditQuiz = React.lazy(() => import("../components/admin/quiz/editQuiz"));
const lazyAdminEditQuizQuestions = React.lazy(() => import("../components/admin/quiz/editQuizQuestions"));
const lazyAdminCreateQuizQuestion = React.lazy(() => import("../components/admin/quiz/createQuizQuestion"));
const lazyStudentQuizQuestions = React.lazy(() => import("../components/admin/quiz/studentQuizQuestions"));
const lazyStudentQuizGrade = React.lazy(() => import("../components/admin/quiz/studentQuizGrade"));
const lazyStudentQuizAnswers = React.lazy(() => import("../components/admin/quiz/studentQuizAnswers"));
const lazyAdminInviteMember = React.lazy(() => import("../components/admin/members/inviteMember"));
const lazyAdminMembers = React.lazy(() => import("../components/admin/members/members"));
const lazyMembersDetails = React.lazy(() => import("../components/admin/members/membersDetails"));
const lazyPerformanceOverview = React.lazy(() => import("../components/admin/performanceOverview/performanceOverview"));

function Routes() {
  const location = useLocation();

  return (
    <>
      {location.pathname === NonAuthRoutes.landingPage ||
      location.pathname === NonAuthRoutes.signup ||
      location.pathname === NonAuthRoutes.complete ||
      location.pathname === NonAuthRoutes.login ||
      location.pathname.includes(NonAuthRoutes.verifyEmail) ||
      location.pathname.includes(NonAuthRoutes.resetPassword) ||
      location.pathname.includes(NonAuthRoutes.joinCourse) ||
      location.pathname === NonAuthRoutes.forgotPassword ? (
        <Suspense
          fallback={
            <div className="flex justify-center items-center h-screen">
              <CitroneLoadingIcon alt="Citrone Loading Icon" className="w-40 lg:w-56" />
            </div>
          }
        >
          <Switch>
            <Route exact path={NonAuthRoutes.landingPage} component={LandingPage} />
            <Route exact path={NonAuthRoutes.signup} component={signUp} />

            <Route exact path={NonAuthRoutes.login} component={login} />
            <Route exact path={NonAuthRoutes.forgotPassword} component={forgotPassword} />

            <Route exact path={`${NonAuthRoutes.verifyEmail}/email/:id`} component={lazyVerifyEmail} />
            <Route exact path={`${NonAuthRoutes.resetPassword}/:token`} component={lazyResetPassword} />
            <Route exact path={`${NonAuthRoutes.joinCourse}/:isRole/:courseId/`} component={lazyJoinCourse} />
            <Route path="*" component={pageNotFound} />
          </Switch>
        </Suspense>
      ) : (
        <>
          <Suspense
            fallback={
              <div className="flex justify-center items-center h-screen">
                <CitroneLoadingIcon alt="Citrone Loading Icon" className="w-40 lg:w-56" />
              </div>
            }
          >
            <Switch>
              <PrivateRoute exact path={AuthRoutes.dashboard} comp={lazyDashboard} />
              <PrivateRoute exact path={AuthRoutes.notifications} comp={lazyNotifications} />
              <PrivateRoute exact path={AuthRoutes.profile} comp={lazyProfile} />
              <PrivateRoute exact path={`${AuthRoutes.dashboardCourses}/:userRole`} comp={dashboardCourses} />
              <PrivateRoute exact path={AuthRoutes.userSettings} comp={userSettings} />
              <PrivateRoute exact path={AuthRoutes.adminPortal} comp={lazyAdminPortal} />
              <PrivateRoute exact path={AuthRoutes.createAcademy} comp={lazyAdminCreateAcademy} />
              <PrivateRoute
                exact
                path={`${AuthRoutes.editAcademy}/:academyName*/:academyId`}
                comp={lazyAdminEditAcademy}
              />
              <PrivateRoute exact path={AuthRoutes.academies} comp={lazyAdminAcademies} />
              <PrivateRoute exact path={`${AuthRoutes.academy}/:academyName*/:academyId`} comp={lazyAdminAcademy} />
              <PrivateRoute
                exact
                path={`${AuthRoutes.createCourse}/:academyName*/:academyId`}
                comp={lazyAdminCreateCourse}
              />
              <PrivateRoute exact path={`${AuthRoutes.course}/:courseTitle*/:courseId`} comp={lazyAdminCourse} />
              <PrivateRoute
                exact
                path={`${AuthRoutes.courseSyllabus}/:courseTitle*/:courseId`}
                comp={lazyAdminCourseSyllabus}
              />
              <PrivateRoute
                exact
                path={`${AuthRoutes.editCourse}/:courseTitle*/:courseId`}
                comp={lazyAdminEditCourse}
              />
              <PrivateRoute
                exact
                path={`${AuthRoutes.createLesson}/:courseTitle*/:courseId`}
                comp={lazyAdminCreateLesson}
              />
              <PrivateRoute exact path={`${AuthRoutes.editLesson}/:courseId/:lessonId`} comp={lazyAdminEditLesson} />
              <PrivateRoute
                exact
                path={`${AuthRoutes.resumeLesson}/:courseId/:lessonId/:lessonIndex/:lessonNumber`}
                comp={lazyResumeLesson}
              />
              <PrivateRoute exact path={`${AuthRoutes.expandLesson}/:courseId/:lessonId`} comp={lazyExpandLesson} />
              <PrivateRoute
                exact
                path={`${AuthRoutes.createAssignment}/:courseId/:assignmentId`}
                comp={lazyAdminCreateAssignment}
              />
              <PrivateRoute
                exact
                path={`${AuthRoutes.editAssignment}/:courseId/:assignmentId`}
                comp={lazyAdminEditAssignment}
              />
              <PrivateRoute
                exact
                path={`${AuthRoutes.studentAssignment}/:courseId/:assignmentId`}
                comp={lazyStudentAssignment}
              />
              <PrivateRoute
                exact
                path={`${AuthRoutes.studentAssignmentAnswer}/:courseTitle*/:courseId`}
                comp={lazyStudentAssignmentAnswer}
              />
              <PrivateRoute
                exact
                path={`${AuthRoutes.studentAssignmentGrade}/:courseId/:assignmentId`}
                comp={lazyStudentAssignmentGrade}
              />
              <PrivateRoute exact path={`${AuthRoutes.createQuiz}/:courseId/:lessonId`} comp={lazyAdminCreateQuiz} />
              <PrivateRoute
                exact
                path={`${AuthRoutes.createQuizQuestion}/:courseId/:lessonId/:quizId`}
                comp={lazyAdminCreateQuizQuestion}
              />
              <PrivateRoute
                exact
                path={`${AuthRoutes.editQuiz}/:courseId/:lessonId/:quizId`}
                comp={lazyAdminEditQuiz}
              />
              <PrivateRoute
                exact
                path={`${AuthRoutes.editQuizQuestions}/:courseId/:lessonId/:quizId`}
                comp={lazyAdminEditQuizQuestions}
              />
              <PrivateRoute
                exact
                path={`${AuthRoutes.studentQuizQuestions}/:courseId/:lessonId/:quizId`}
                comp={lazyStudentQuizQuestions}
              />
              <PrivateRoute
                exact
                path={`${AuthRoutes.studentQuizGrade}/:courseId/:lessonId/:quizId`}
                comp={lazyStudentQuizGrade}
              />
              <PrivateRoute
                exact
                path={`${AuthRoutes.studentQuizAnswers}/:courseId/:lessonId/:quizId`}
                comp={lazyStudentQuizAnswers}
              />
              <PrivateRoute exact path={`${AuthRoutes.members}/:courseId`} comp={lazyAdminMembers} />
              <PrivateRoute exact path={`${AuthRoutes.inviteMembers}/:courseId`} comp={lazyAdminInviteMember} />
              <PrivateRoute exact path={`${AuthRoutes.membersDetails}/:courseId`} comp={lazyMembersDetails} />
              <PrivateRoute exact path={`${AuthRoutes.performanceOverview}/:courseId`} comp={lazyPerformanceOverview} />
              <PrivateRoute path="*" comp={pageNotFound} />
            </Switch>
          </Suspense>
        </>
      )}
    </>
  );
}
export default Routes;
